import { types } from "@redux/actions/phoneSystemActions";

const initialState = {
    sms_to_send: {}, // Information that is sent with the SMS.
    transfer_to_phone: "", // The phone number that is in the top box.
    number_to_call: "", // The phone number that should be called with Hringja.
    in_phone_call: false, // If the user is in a phone call.
    has_incoming_call: false, // If the user has an incoming call.
    has_outgoing_call: false, // If the user is calling somewhere.
    caller_information: null, // Information about the caller.
    status: { connected: false, available: false, text: "", color: "" }, // The status of the phone system.
    send_sms: true,
    call_start_time: null,
    parked_calls: [],
    auto_answer: false,
    queue_name: null,
    my_calls: [],
    waiting: { waiting1819: 0, waitingOther: 0 },
    auto_answer_timer_active: false,
    is_playing_audio: false,
    call_id: null,
    email_modal: {
        recipient: "",
    },
    status_log: [
        {
            date: new Date(),
            text: "Símkerfi opnað",
            color: "#000099",
        },
    ],
};

const phoneSystemReducer = (state = initialState, action) => {
    var newState = { ...state };
    switch (action.type) {
        case types.get_phone_info:
            var newSmsToSend = { ...action.payload };
            if (state?.in_phone_call) {
                newSmsToSend.sms_recipient = state?.caller_information?.phone;
            }
            return {
                ...state,
                sms_to_send: { ...state.sms_to_send, ...newSmsToSend },
            };

        case types.delete_phone_info:
            return {
                ...state,
                sms_to_send: null,
            };

        case types.set_my_calls:
            return {
                ...state,
                ...action.payload,
            };
        case types.add_status:
            var newLog = [...state.status_log];
            newLog.push(action.payload.status);
            return {
                ...state,
                status_log: newLog,
            };
        case types.clear_phone_info:
            return {
                ...newState,
                sms_to_send: {},
            };
        case types.set_phone_info:
            return {
                ...newState,
                sms_to_send: {
                    ...state.sms_to_send,
                    ...action.payload,
                },
            };

        case types.set_auto_answer_timer_active:
            return {
                ...newState,
                auto_answer_timer_active: action.payload,
            };

        case types.set_send_sms:
            return {
                ...newState,
                ...{ send_sms: action.payload },
            };
        case types.set_is_playing_audio:
            return {
                ...newState,
                ...{ is_playing_audio: action.payload },
            };
        case types.set_waiting:
            return {
                ...newState,
                ...{ waiting: action.payload },
            };
        case types.clear_current_phone:
            return {
                ...state,
                transfer_to_phone: "",
            };
        case types.set_selected_phone:
            return {
                ...state,
                ...action.payload,
            };
        case types.set_number_to_call:
            return {
                ...state,
                ...action.payload,
            };
        case types.set_status:
            return {
                ...state,
                status: {
                    ...state.status,
                    ...action.payload.status,
                },
            };
        case types.set_caller_info:
            return {
                ...state,
                ...action.payload,
            };
        case types.make_call:
            return {
                ...state,
                //has_outgoing_call: true,
            };

        // STATE CHANGES
        case types.has_incoming_call:
            return {
                ...state,
                has_incoming_call: true,
                queue_name: action.payload.queue_name,
            };
        case types.has_outgoing_call:
            return {
                ...state,
                has_outgoing_call: true,
            };
        case types.clear_calls:
            return {
                ...state,
                has_outgoing_call: false,
                has_incoming_call: false,
                queue_name: null,
                in_phone_call: false,
                caller_information: null,
                call_start_time: null,
                call_id: null,
            };
        case types.call_connected:
            return {
                ...state,
                in_phone_call: true,
                ...action.payload,
            };

        case types.set_parked_calls:
            return {
                ...state,
                ...action.payload,
            };
        case types.set_script:
            return {
                ...state,
                ...action.payload,
            };
        case types.clear_script:
            return {
                ...state,
                script: null,
                audio: null,
            };
        case types.set_attendant_status:
            return {
                ...state,
                status: {
                    ...state.status,
                    attendantStatus: action.payload.attendantStatus,
                },
            };
        case types.toggle_email_modal:
            return {
                ...state,
                showEmailModal: !state.showEmailModal,
            };

        case types.toggle_auto_answer:
            return {
                ...state,
                auto_answer: !state.auto_answer,
            };
        case types.set_call_id:
            return {
                ...state,
                call_id: action.payload.id,
            };

        case types.set_email_modal_info:
            return {
                ...state,
                email_modal: {
                    ...state.email_modal,
                    recipient: action.payload.recipient,
                },
            };
        case types.clear_email_modal_info:
            return {
                ...state,
                email_modal: {
                    ...initialState.email_modal,
                },
            };

        default: {
            return state;
        }
    }
};

export default phoneSystemReducer;
