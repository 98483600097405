import { fetcherNew1819 } from "@utils/fetcherNew1819";
import { phoneSystemFetcher } from "@utils/phoneSystemFetcher";

export const types = {
    get_phone_info: "get_phone_info",
    delete_phone_info: "delete_phone_info",
    set_selected_phone: "set_selected_phone",
    clear_phone_info: "clear_phone_info",
    set_phone_info: "set_phone_info",
    clear_current_phone: "clear_current_phone",
    set_status: "set_status",
    set_number_to_call: "set_number_to_call",
    make_call: "make_call",
    has_incoming_call: "has_incoming_call",
    has_outgoing_call: "has_outgoing_call",
    set_caller_info: "set_caller_info",
    clear_calls: "clear_calls",
    call_connected: "call_connected",
    set_parked_calls: "set_parked_calls",
    set_attendant_status: "set_attendant_status",
    set_script: "set_script",
    clear_script: "clear_script",
    add_status: "add_status",
    toggle_email_modal: "toggle_email_modal",
    toggle_auto_answer: "toggle_auto_answer",
    set_my_calls: "set_my_calls",
    set_waiting: "set_waiting",
    set_auto_answer_timer_active: "set_auto_answer_timer_active",
    set_send_sms: "set_send_sms",
    set_is_playing_audio: "set_is_playing_audio",
    set_call_id: "set_call_id",
    set_email_modal_info: "set_email_modal_info",
    clear_email_modal_info: "clear_email_modal_info",
};

export const addStatus = (status) => (dispatch) => {
    dispatch({
        type: types.add_status,
        payload: { status: { date: new Date(), color: "black", ...status } },
    });
};

export const getPhoneInfo = (phone, compid) => (dispatch, getState) => {
    if (!phone) {
        phone = getState()?.phoneSystem?.transfer_to_phone;
    }
    if (!compid) {
        compid = getState()?.phoneSystem?.transfer_to_company_id;
    }

    var url = `https://old.1819.is/api/lookupsms?q=${phone}`;
    if (compid) url = url + `&compid=${compid}`;

    fetch(url, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.length > 0) {
                res = res[0];
                res.phone = phone;
                if (res.address == null || res.address == undefined || res.address == "") {
                    res.address = "";
                }
                if (!res.zip == null || res.zip == undefined || res.zip == "") {
                    res.zip = "";
                }
                res.address = [res.address, res.zip].join(", ");
                if (res.address == ", ") {
                    res.address = "";
                }
                dispatch({
                    type: types.get_phone_info,
                    payload: res,
                });
            } else {
                dispatch({
                    type: types.delete_phone_info,
                });
            }
        })
        .catch(() => {});
};

export const sendSMS = (phoneNumber) => async (dispatch, getState) => {
    const smsToSend = getState()?.phoneSystem?.sms_to_send;
    if (!smsToSend) {
        return;
    }
    const { name, address, phone } = smsToSend;
    if (!phone) return;
    if (!phoneNumber) {
        dispatch(
            addStatus({
                text: 'Númer vantar í "Símanr. viðtakanda"',
                color: "#cf0000",
            })
        );
        return;
    }

    var details = {
        recipient: phoneNumber,
        phone: phone,
        name: name,
        address: address || "",
    };
    try {
        await fetcherNew1819("mitt/sms/thjonustuver", "POST", details);
        dispatch(
            addStatus({ text: `Upplýsingar um ${phone} sent á ${phoneNumber}.`, color: "green" })
        );
        dispatch(logSendSMS(phoneNumber));
    } catch (e) {
        dispatch(
            addStatus({
                text: `Villa við að senda upplýsingar um ${phone} á ${phoneNumber}.`,
                color: "#cf0000",
            })
        );
    } finally {
        dispatch({ type: types.clear_phone_info });
        dispatch({ type: types.clear_current_phone });
    }
};

export const clearPhoneInfo = () => (dispatch) => {
    dispatch({
        type: types.clear_phone_info,
    });
};

export const setAutoAnswerTimerActive = (active) => (dispatch) => {
    dispatch({
        type: types.set_auto_answer_timer_active,
        payload: active,
    });
};

export const setShouldSendSMS = (sendSMS) => (dispatch) => {
    dispatch({
        type: types.set_send_sms,
        payload: sendSMS,
    });
};

export const setIsPlayingAudio = (isPlaying) => (dispatch) => {
    dispatch({
        type: types.set_is_playing_audio,
        payload: isPlaying,
    });
};

export const setPhoneInfo = (sms_to_send) => (dispatch) => {
    dispatch({
        type: types.set_phone_info,
        payload: sms_to_send,
    });
};

let timeout = null;

export const setSelectedPhone = (phone, compid) => (dispatch) => {
    dispatch({
        type: types.set_selected_phone,
        payload: {
            transfer_to_phone: phone,
            transfer_to_company_id: compid,
        },
    });
    if (phone.length === 3 || phone.length === 4 || phone.length === 7) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            dispatch(getPhoneInfo());
        }, 300);
    }
};

export const setNumberToCall = (phone) => (dispatch) => {
    dispatch({
        type: types.set_number_to_call,
        payload: {
            number_to_call: phone,
        },
    });
};

export const setStatus = (status) => (dispatch) => {
    dispatch({
        type: types.set_status,
        payload: { status: status },
    });
};

// Phone System Connection
export const makeCall = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    var numberToCall = getState()?.phoneSystem?.number_to_call;

    if (!numberToCall.startsWith("+") || !numberToCall.startsWith("00")) {
        numberToCall = "+354" + numberToCall;
    }
    if (numberToCall) {
        dispatch(logOutboundCall());
        await phoneSystemFetcher("makecall", [extension, numberToCall, "Web"]);
        dispatch(getAllCalls());
        dispatch({
            type: types.make_call,
        });
    }
};

export const transferCall = (phone) => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    if (phone.length === 3 || phone.length === 4 || phone.length === 7) {
        dispatch(logTransferCall(phone));
        var result = await phoneSystemFetcher("transfer", [extension, "+354" + phone]);
        if (result == "true") {
            dispatch(dropCall());
            let callerPhone;
            if (getState()?.phoneSystem.has_outgoing_call) {
                callerPhone = getState()?.phoneSystem?.number_to_call;
            } else {
                callerPhone = getState()?.phoneSystem?.caller_information?.phone;
            }
            dispatch(addStatus({ text: `${callerPhone} áframsent á ${phone}`, color: "green" }));
            return;
        } else {
            dispatch(
                addStatus({
                    text: `Möguleg villa við áframsendingu á ${
                        getState()?.phoneSystem?.caller_information?.phone
                    } til ${phone}`,
                    color: "orange",
                })
            );
        }
    }

    // dispatch({
    //     type: types.clear_phone_info,
    // });
};

export const dropCall = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("drop", [extension]);
    dispatch(getAllCalls());

    // dispatch({
    //     type: types.clear_phone_info,
    // });
};

export const getAllCalls = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    var status = await phoneSystemFetcher("showallcalls", []);
    if (status == null || status == undefined) {
        return;
    }
    status = status.replace("<html><head></head><body>", "");
    status = status.replace("</body></html>", "");
    status = status.split("<br>");
    status = status.map((item) => {
        return item.split(";");
    });
    var allCalls = [];
    var waiting1819 = 0;
    var waitingOther = 0;
    status.forEach((item) => {
        if (item == "") {
            return;
        }
        var result = {};
        item.forEach((item2) => {
            var splitted = item2.split("=");
            return (result[splitted[0]] = splitted[1]);
        });
        if (result?.DN == "8001") {
            waiting1819++;
        } else if (result?.DN?.startsWith("8")) {
            waitingOther++;
        }
        allCalls.push(result);
    });
    var myCalls = allCalls.filter((call) => call.DN == extension);
    var currentCall = myCalls[0];

    dispatch({
        type: types.set_my_calls,
        payload: { my_calls: myCalls },
    });
    dispatch({
        type: types.set_waiting,
        payload: { waiting1819, waitingOther },
    });

    // var parkedCalls = allCalls.filter((call) => call.DN == "*0");
    // dispatch(setParkedCalls(parkedCalls));
    if (!currentCall) {
        dispatch(clearCalls());
        dispatch(clearScript());
        return;
    }

    if (currentCall.S == "Ringing") {
        if (!getState()?.phoneSystem?.script) {
            dispatch(getScript(currentCall?.Queue_Nummer));
        }
        dispatch(incomingCall(currentCall.EP, currentCall.Queue_Name));
    } else if (currentCall.S == "Dialing") {
        dispatch(outgoingCall(currentCall.EP));
    } else if (currentCall.S == "Connected") {
        dispatch(callConnected());
    }
};

// export const parkCall = () => async (dispatch, getState) => {
//     var extension = getState()?.auth?.user?.extension_3cx;
//     if (!extension) {
//         return;
//     }
//     await phoneSystemFetcher("park", [extension]);
//     dispatch(getAllCalls());
// };

// export const unparkCall = () => async (dispatch, getState) => {
//     var extension = getState()?.auth?.user?.extension_3cx;
//     if (!extension) {
//         return;
//     }
//     await phoneSystemFetcher("unpark", [extension, "Web"]);
//     dispatch(getAllCalls());
// };

export const log1819Call = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    const body = {
        phone: phoneSystem?.caller_information?.phone,
    };
    try {
        const call = await fetcherNew1819("stats/call/1819", "POST", body);
        if (call?.id) {
            dispatch(setCallID(call.id));
        }
        dispatch(addStatus({ text: `1819 símtal skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        dispatch(addStatus({ text: "Villa við að skrá símtal", color: "#cf0000" }));
    }
};

export const logExternalCall = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    const body = {
        phone: phoneSystem?.caller_information?.phone,
        queue: parseInt(phoneSystem?.my_calls?.[0]?.Queue_Nummer),
    };
    try {
        const call = await fetcherNew1819("stats/call/external", "POST", body);
        if (call?.id) {
            dispatch(setCallID(call.id));
        }
        dispatch(addStatus({ text: `Símsvörunar símtal skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        dispatch(addStatus({ text: "Villa við að skrá símsvörunarsímtal", color: "#cf0000" }));
    }
};

export const logOutboundCall = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    const body = {
        phone: phoneSystem?.number_to_call,
    };
    try {
        const call = await fetcherNew1819("stats/call/outbound", "POST", body);
        if (call?.id) {
            dispatch(setCallID(call.id));
        }
        dispatch(addStatus({ text: `Útsímtal skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá útsímtal", color: "#cf0000" }));
    }
};

export const logTransferCall = (transferPhone) => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    if (phoneSystem?.call_id == null) {
        dispatch(
            addStatus({
                text: "Ekkert Call ID. Vinsamlegast ekki svara í 3CXPhone.",
                color: "#cf0000",
            })
        );
    }
    let phone;
    if (phoneSystem.has_outgoing_call) {
        phone = phoneSystem?.number_to_call;
    } else {
        phone = phoneSystem?.caller_information?.phone;
    }

    const body = {
        phone: phone,
        call_id: phoneSystem?.call_id,
        transfer_phone: transferPhone,
    };
    try {
        await fetcherNew1819("stats/call/transfer", "POST", body);
        dispatch(addStatus({ text: "Áframsending skráð", color: "green" }));
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá áframsendingu", color: "#cf0000" }));
    }
};

export const logSendSMS = (phone) => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;

    if (phoneSystem?.call_id == null) {
        dispatch(
            addStatus({
                text: "Ekkert Call ID. Vinsamlegast ekki svara í 3CXPhone.",
                color: "#cf0000",
            })
        );
    }

    const body = {
        phone: phone,
        call_id: phoneSystem?.call_id,
        sms_phone: phoneSystem?.sms_to_send?.phone,
    };
    try {
        const call = await fetcherNew1819("stats/call/sms", "POST", body);
        dispatch(addStatus({ text: `SMS skráð með ID ${call.id}`, color: "green" }));
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá SMS sendingu", color: "#cf0000" }));
    }
};

export const logGaveInfoAboutNumber = () => async (dispatch, getState) => {
    const phoneSystem = getState()?.phoneSystem;
    if (phoneSystem?.call_id == null) {
        dispatch(
            addStatus({
                text: "Ekkert Call ID. Vinsamlegast ekki svara í 3CXPhone.",
                color: "#cf0000",
            })
        );
    }
    const body = {
        phone: phoneSystem?.caller_information?.phone,
        call_id: phoneSystem?.call_id,
        info_phone: phoneSystem?.sms_to_send?.phone,
    };
    try {
        await fetcherNew1819("stats/call/info", "POST", body);
        dispatch(
            addStatus({
                text: `Þú hefur veitt upplýsingar um ${phoneSystem?.sms_to_send?.phone}`,
                color: "green",
            })
        );
    } catch (e) {
        console.log(e);
        dispatch(addStatus({ text: "Villa við að skrá Gaf upplýsingar", color: "#cf0000" }));
    }
};

export const answerCall = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("answer", [extension]);

    let queueNumber = getState()?.phoneSystem?.my_calls?.[0]?.Queue_Nummer;

    if (queueNumber == "8001") {
        dispatch(log1819Call());
    } else {
        dispatch(logExternalCall());
    }
    dispatch(getAllCalls());
};

export const setCallID = (id) => async (dispatch) => {
    dispatch({
        type: types.set_call_id,
        payload: { id },
    });
};

export const logOut = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("logout", [extension]);
    dispatch(addStatus({ text: "Aftengdist", color: "#cf0000" }));
    dispatch(getAttendantStatus());
};

export const setLunch = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("notready", [extension]);
    dispatch(addStatus({ text: "Skráð(ur) í mat", color: "#FFA327" }));
    dispatch(getAttendantStatus());
};

export const getAttendantStatus = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    var status = await phoneSystemFetcher("showstatus", [extension]);
    dispatch({
        type: types.set_attendant_status,
        payload: { attendantStatus: status },
    });
};

export const setStatusReady = () => async (dispatch, getState) => {
    var extension = getState()?.auth?.user?.extension_3cx;
    if (!extension) {
        return;
    }
    await phoneSystemFetcher("ready", [extension]);
    dispatch(addStatus({ text: "Tengdist", color: "green" }));
    dispatch(getAttendantStatus());
};

export const incomingCall = (fromPhoneNumber, queue) => (dispatch, getState) => {
    if (
        !getState()?.phoneSystem?.auto_answer_timer_active &&
        getState()?.phoneSystem?.auto_answer
    ) {
        dispatch(setAutoAnswerTimerActive(true));
        setTimeout(() => {
            dispatch(answerCall());
            if (getState()?.phoneSystem?.script) {
                dispatch(setShouldSendSMS(false));
            } else {
                dispatch(setShouldSendSMS(true));
            }
            setTimeout(() => {
                dispatch(setAutoAnswerTimerActive(false));
            }, 1500);
        }, 2000);
    }

    dispatch({
        type: types.has_incoming_call,
        payload: { queue_name: queue },
    });

    if (getState()?.phoneSystem?.caller_information) return;
    dispatch(addStatus({ text: fromPhoneNumber + " hringir" }));

    var url = `https://old.1819.is/api/lookupsms?q=${fromPhoneNumber}`;

    fetch(url, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.length > 0) {
                res = res[0];
                res.phone = fromPhoneNumber;
            } else {
                res[0] = {};
                res.phone = fromPhoneNumber;
            }
            dispatch({
                type: types.set_caller_info,
                payload: { caller_information: res },
            });
            if (res?.name) {
                dispatch(addStatus({ text: res?.name }));
            }
        })
        .catch(() => {});
};

export const outgoingCall = (toPhoneNumber) => (dispatch, getState) => {
    dispatch({
        type: types.has_outgoing_call,
    });
    if (getState()?.phoneSystem?.caller_information) return;

    var url = `https://old.1819.is/api/lookupsms?q=${toPhoneNumber}`;
    fetch(url, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((res) => {
            if (res.length > 0) {
                res = res[0];
                res.phone = toPhoneNumber;
            } else {
                res = null;
            }
            dispatch({
                type: types.set_caller_info,
                payload: { caller_information: res },
            });
        })
        .catch(() => {});
};

export const clearCalls = () => (dispatch) => {
    dispatch({
        type: types.clear_calls,
    });
    dispatch(setAutoAnswerTimerActive(false));
};

export const callConnected = () => (dispatch, getState) => {
    const callStartTime = getState()?.phoneSystem?.call_start_time;
    dispatch({
        type: types.call_connected,
        payload: {
            call_start_time: callStartTime ? callStartTime : new Date().toString(),
        },
    });
};

// export const setParkedCalls = (calls) => (dispatch) => {
//     dispatch({
//         type: types.set_parked_calls,
//         payload: { parked_calls: calls },
//     });
// };

// Script

export const getScript = (fromExtension) => async (dispatch, getState) => {
    if (!fromExtension) {
        return;
    }
    try {
        var company = await fetcherNew1819(`mitt/answering/company?queue-ext=${fromExtension}`);
        if (company == null) {
            return;
        }
        const shouldPlayAudio =
            company?.initial_audio_url != null &&
            company?.initial_audio_url != undefined &&
            !getState()?.phoneSystem?.is_playing_audio;

        if (shouldPlayAudio) {
            dispatch(setIsPlayingAudio(true));
            const audio = new Audio(company?.initial_audio_url);
            audio.play();
            audio.onended = () => {
                dispatch(setIsPlayingAudio(false));
            };
        }

        dispatch({
            type: types.set_script,
            payload: { script: company?.main_script, audio: company?.initial_audio_url },
        });
    } catch (e) {
        console.log(e);
    }
};

export const clearScript = () => async (dispatch, getState) => {
    if (getState().phoneSystem.script) {
        dispatch({
            type: types.clear_script,
        });
    }
};

export const toggleEmailModal = () => async (dispatch) => {
    dispatch({
        type: types.toggle_email_modal,
    });
};

export const toggleAutoAnswer = () => async (dispatch) => {
    dispatch({
        type: types.toggle_auto_answer,
    });
};

export const setEmailModalInfo = (info) => async (dispatch) => {
    dispatch({
        type: types.set_email_modal_info,
        payload: info,
    });
};

export const clearEmailModalInfo = (info) => async (dispatch) => {
    dispatch({
        type: types.clear_email_modal_info,
        payload: info,
    });
};
