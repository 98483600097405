import React from "react";
import { useRouter, withRouter } from "next/router";
import ReactGA_4 from "react-ga4";
import dynamic from "next/dynamic";
import { connect } from "react-redux";

const Page = dynamic(() => import("@components/layout/page"));
const FrontPage = dynamic(() => import("@components/front_page/front_page"));
const SearchResults = dynamic(() => import("@components/front_page/search_results_container"));

function TorgidPage() {
    // Translation
    const router = useRouter();

    React.useEffect(() => {
        ReactGA_4.send("pageview");
    }, []);

    return (
        <>
            {router.query.q ? (
                <Page showsSearchBar showsFooter={false}>
                    <SearchResults query={router.query.q} />
                </Page>
            ) : (
                <Page>
                    <FrontPage />
                </Page>
            )}
        </>
    );
}

const mapState = (state) => {
    return {
        name: state.name,
    };
};

const mapDis = () => {
    return {
        callAction: () => {},
    };
};

export default connect(mapState, mapDis)(withRouter(TorgidPage));
