import React from "react";
import Link from "next/link";
import { Col, Container, Image, Row, Title } from "@components/ui";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import { THEME_COLOR } from "@utils/constants";

function Footer({ showsNavigation = true }) {
    if (!showsNavigation) {
        return (
            <footer className="footer torgid-background">
                <div className="footer-container">
                    <Container>
                        <div className="footer-content">
                            <div className="bottom-text torgid-text-secondary">
                                1819 - Akralind 6 - 201 Kópavogur - 1819@1819.is | Allur réttur
                                áskilinn © {new Date().getFullYear()} - 1819 miðlar
                            </div>
                        </div>
                    </Container>
                </div>
            </footer>
        );
    }
    return (
        <footer className="footer torgid-background">
            <div className="footer-container">
                <Container>
                    <div className="footer-content">
                        <Navigation />
                        <div className="bottom-text torgid-text-secondary">
                            1819 - Akralind 6 - 201 Kópavogur - Sími: 546-1819 - 1819@1819.is |
                            Allur réttur áskilinn © {new Date().getFullYear()} - 1819 miðlar
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

function Navigation() {
    const t = GetTranslatedStrings();
    return (
        <>
            <div className="footer-navigation">
                <div className="row">
                    <div className="row-item col-lg-8 col-sm-12 order-4">
                        <Row>
                            <Col sm={7}>
                                <Title style={{ color: THEME_COLOR }}>
                                    {t.footer_opening_hours}
                                </Title>
                                <Row>
                                    <Col col={6}>
                                        <Title size={5} style={{ color: THEME_COLOR }}>
                                            {t.footer_thjonustuver}
                                        </Title>
                                        <Title
                                            size={6}
                                            style={{
                                                color: THEME_COLOR,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            {t.footer_weekdays}
                                        </Title>
                                        <p
                                            className="torgid-text-secondary"
                                            style={{
                                                fontSize: 25,
                                                marginBottom: 5,
                                            }}
                                        >
                                            08:00 - 21:00
                                        </p>
                                        <Title
                                            size={6}
                                            style={{
                                                color: THEME_COLOR,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            {t.footer_weekends}
                                        </Title>
                                        <p
                                            className="torgid-text-secondary"
                                            style={{
                                                fontSize: 25,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            09:00 - 21:00
                                        </p>
                                    </Col>
                                    <Col col={6}>
                                        <Title size={5} style={{ color: THEME_COLOR }}>
                                            {t.footer_skrifstofa}
                                        </Title>
                                        <Title
                                            size={6}
                                            style={{
                                                color: THEME_COLOR,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            {t.footer_weekdays}
                                        </Title>
                                        <p
                                            className="torgid-text-secondary"
                                            style={{
                                                fontSize: 25,
                                                marginBottom: 5,
                                            }}
                                        >
                                            09:00 - 17:00
                                        </p>
                                        <Title
                                            size={6}
                                            style={{
                                                color: THEME_COLOR,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            {t.footer_weekends}
                                        </Title>
                                        <p
                                            className="torgid-text-secondary"
                                            style={{
                                                fontSize: 25,
                                                padding: 0,
                                                margin: 0,
                                            }}
                                        >
                                            {t.opening_hours_closed}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={5}>
                                <Title style={{ color: THEME_COLOR }}>
                                    {t.footer_thjonustuver}
                                </Title>
                                <p className="torgid-text-secondary" style={{ fontSize: 30 }}>
                                    1819
                                </p>
                                <Title style={{ color: THEME_COLOR }}>{t.footer_skrifstofa}</Title>
                                <p className="torgid-text-secondary" style={{ fontSize: 30 }}>
                                    546-1819
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <div className="row-item col-lg-2 col-sm-4 col-6 order-1">
                        <Link href="/um/thjonusta/vorur">
                            <a>
                                <Title style={{ color: THEME_COLOR }}>Þjónusta</Title>
                            </a>
                        </Link>
                        <ul>
                            <LinkListItem
                                href="/um/thjonusta/upplysinganumerid"
                                text="Upplýsinganúmerið"
                            />
                            <LinkListItem href="/um/thjonusta/simsvorun" text="Símsvörun" />
                            <LinkListItem
                                href="/um/thjonusta/fyrirtaekjaskraning"
                                text="Fyrirtækjaskráning"
                            />
                            <LinkListItem
                                href="/um/thjonusta/synileiki"
                                text="Sýnileiki hjá 1819"
                            />
                            <LinkListItem
                                href="/um/thjonusta/auglysingar"
                                text="Auglýsingar hjá 1819"
                            />
                        </ul>
                    </div>
                    <div className="row-item col-lg-2 col-sm-4 col-6 order-2">
                        <Link href="/um/fyrirtaekid/um">
                            <a>
                                <Title style={{ color: THEME_COLOR }}>Fyrirtækið</Title>
                            </a>
                        </Link>{" "}
                        <ul>
                            <LinkListItem href="/um/fyrirtaekid/um" text="Um 1819" />
                            <LinkListItem href="/um/fyrirtaekid/starfsfolk" text="Starfsfólk" />
                            <LinkListItem href="/um/fyrirtaekid/starfsumsokn" text="Starfsumsókn" />
                            <LinkListItem href="/um/fyrirtaekid/hafa-samband" text="Hafa samband" />
                        </ul>
                    </div>
                </div>

                <div className="d-flex flex-row-reverse">
                    <Link href="https://www.facebook.com/1819.is/" prefetch={false}>
                        <a target={"_blank"}>
                            <Image
                                className="social-media"
                                alt="1819 á Facebook"
                                src="/img/social/facebook.svg"
                            />
                        </a>
                    </Link>
                    <Link href="https://www.instagram.com/1819midlar/" prefetch={false}>
                        <a target={"_blank"} style={{ marginRight: 20 }}>
                            {" "}
                            <Image
                                className="social-media"
                                alt="1819 á Instagram"
                                src="/img/social/instagram.svg"
                            />
                        </a>
                    </Link>
                    <Link href="https://www.linkedin.com/company/1819-is/" prefetch={false}>
                        <a target={"_blank"} style={{ marginRight: 20 }}>
                            <Image
                                className="social-media"
                                alt="1819 á LinkedIn"
                                src="/img/social/linkedin.svg"
                            />
                        </a>
                    </Link>
                </div>
            </div>
        </>
    );
}

function LinkListItem({ href, text }) {
    return (
        <li>
            <Link href={href}>
                <a>{text}</a>
            </Link>
        </li>
    );
}
export default Footer;
