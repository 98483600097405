import React from "react";
import { Image } from "@components/ui";
import Link from "next/link";

export const HeaderLogo = () => (
    <Link href="/">
        <a style={{ flexShrink: 0 }}>
            <Image
                className="logo-container"
                width="29px"
                src="/img/1819logo-white.svg"
                alt="1819 merki"
            />
        </a>
    </Link>
);
