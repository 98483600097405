import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import Link from "next/link";
import { HeaderLogo } from "./logo";
import HamburgerIcon from "@components/icons/HamburgerIcon";
import useUser from "@hooks/use_user";
import { FlexContainer } from "@components/ui/flex_container";
import dynamic from "next/dynamic";
const T1819SearchBar = dynamic(() => import("@components/T1819SearchBar"));

function PageHeader({ onMenuClick, showsSearchBar, height }) {
    const user = useUser();
    const router = useRouter();
    return (
        <header className="page-header" style={{ height }}>
            {!showsSearchBar && router.pathname == "/" && (
                <Link href="/um/thjonusta/simsvorun">
                    <a>
                        <div
                            className=" d-none d-md-flex align-items-center justify-content-center"
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                verticalAlign: "center",
                                position: "absolute",
                                color: "#e8b290",
                                fontWeight: "bold",
                                fontSize: "25px",
                                left: 0,
                                right: 0,
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "300px",
                                height: 65,
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            Prófaðu símsvörun frítt!
                        </div>
                    </a>
                </Link>
            )}

            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        maxWidth: 1296,
                        marginLeft: 20,
                        marginRight: 20,
                        alignItems: "center",
                    }}
                >
                    <HeaderLogo />

                    {showsSearchBar ? (
                        <T1819SearchBar showGoogle small style={{ marginLeft: 5 }} />
                    ) : (
                        <div style={{ flex: 1 }} />
                    )}

                    <div style={{ flexShrink: 1 }}>
                        <FlexContainer alignItems="center" style={{ height: 65 }}>
                            <FlexContainer className="header-accessories">
                                {user ? (
                                    <Link href="/mitt/notandi">
                                        <a>
                                            <div
                                                className="d-flex flex-column align-items-center"
                                                style={{
                                                    color: "white",
                                                    fontSize: "13px",
                                                    marginLeft: "20px",
                                                    marginTop: 7,
                                                }}
                                            >
                                                <img
                                                    src="/img/admin-user.png"
                                                    style={{
                                                        height: 23,
                                                        marginTop: 8,
                                                        marginBottom: 1,
                                                    }}
                                                    alt="Notandi"
                                                />
                                                {user?.full_name?.split(" ")[0]}
                                            </div>
                                        </a>
                                    </Link>
                                ) : (
                                    <MenuButton
                                        title="Skráðu þig!"
                                        src="/img/admin-user.png"
                                        alt="Notandi"
                                        link="/innskraning"
                                    />
                                )}

                                <MenuButton src="/img/iceland.png" alt="Íslandskort" link="/kort" />
                            </FlexContainer>
                            <a rel="nofollow">
                                <HamburgerIcon
                                    width="40px"
                                    height="40px"
                                    color="white"
                                    style={{ marginLeft: 5 }}
                                    onClick={onMenuClick}
                                />
                            </a>
                        </FlexContainer>
                    </div>
                </div>
            </div>
        </header>
    );
}

function MenuButton({ onClick, title, ...props }) {
    return (
        <Link href={props.link ? props.link : "#"}>
            <a
                title={title}
                onClick={() => {
                    if (onClick) onClick();
                }}
            >
                <img
                    alt={props.alt}
                    style={{
                        height: 65,
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                    src={props.src}
                />
            </a>
        </Link>
    );
}

export default PageHeader;
