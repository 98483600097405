export const WEBPAGE_TITLE = "1819 miðlar";
export const API_ENDPOINT = "https://api.torgid1819.com/api/";
export const API_1819_ENDPOINT = process.env.API_1819_URL
    ? process.env.API_1819_URL
    : "https://old.1819.is/api/";
export const API_NEW_1819_ENDPOINT = process.env.API_NEW_1819_URL
    ? process.env.API_NEW_1819_URL
    : "https://prod.1819.is/api/v1/";
export const APP_KEY = "3f8a97e6-51ea-44d7-a9c9-87ae6040f7fb";
export const GOOGLE_GA_MEASUREMENT_ID = "G-NYZBET9SFB";
export const GOOGLE_GA_TRACKING_ID = "UA-54712412-5";
export const PHONE_SYSTEM_API = "https://hysing178.bodleid.is:8889/";
export const THEME_COLOR = "#174d6f";
export const ISLAND_ID = process.env.ISLAND_ID ? process.env.ISLAND_ID : "1819.is";
export const MITT_MAX_CONTENT_WIDTH = 1150;

export const MONTH_NAMES = [
    "janúar",
    "febrúar",
    "mars",
    "apríl",
    "maí",
    "júní",
    "júlí",
    "ágúst",
    "september",
    "október",
    "nóvember",
    "desember",
];
