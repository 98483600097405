import React from "react";
import classNames from "classnames";

export const FlexContainer = ({
    children,
    className,
    justifyContent,
    alignItems,
    column,
    gap,
    ...props
}) => {
    return (
        <div
            {...props}
            style={{ gap: gap, ...props.style }}
            className={classNames(
                "d-flex",
                {
                    [`justify-content-${justifyContent}`]: justifyContent,
                    [`align-items-${alignItems}`]: alignItems,
                    "flex-column": column,
                },
                className
            )}
        >
            {children}
        </div>
    );
};
