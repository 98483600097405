import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoading from "react-loading";
import { FlexContainer } from "./flex_container";
import { THEME_COLOR } from "@utils/constants";

export const buttonSize = {
    small: "sm",
    large: "lg",
};

export function Button({
    text,
    onClick,
    type = "primary",
    icon,
    image,
    color = THEME_COLOR,
    isBlock,
    isDisabled,
    size,
    isLoading,
    inverted,
    className,
    ...props
}) {
    return (
        <button
            {...props}
            disabled={isDisabled || isLoading}
            style={{
                backgroundColor: inverted ? color + "33" : color,
                color: inverted ? color : "white",
                borderWidth: 0,
                borderRadius: 50,
                paddingTop: 0,
                paddingBottom: 0,
                whiteSpace: "nowrap",
                ...props.style,
            }}
            onClick={onClick}
            className={classNames("btn", className, {
                [`btn-${type}`]: type,
                [`btn-${size}`]: size,
                "btn-block": isBlock,
                "btn-disabled": isDisabled,
            })}
        >
            {isLoading ? (
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: props?.style?.height || 40, position: "relative" }}
                >
                    <ReactLoading type={"bubbles"} color={"white"} width="100px" height="100px" />
                </FlexContainer>
            ) : (
                <>
                    {icon && <FontAwesomeIcon icon={icon} />}
                    {image && <img src={image} style={{ width: "1.2rem", height: "1.2rem" }} />}
                    {(icon || image) && text && <span style={{ marginLeft: "10px" }} />}
                    {text}
                </>
            )}
        </button>
    );
}
