import React, { useEffect } from "react";
import authenticated_route from "@navigation/authenticated_route";
import Page from "@components/layout/page";
import SideBar from "@components/side_bar";
import { FlexContainer } from "@components/ui/flex_container";
import useUser from "@hooks/use_user";
import MenuBuilder from "@config/sidebars/innra_routes/MenuBuilder";

function InnraLayout({ children }) {
    const user = useUser();
    const [menu, setMenu] = React.useState([]);
    useEffect(() => {
        setMenu(MenuBuilder(user));
    }, [user]);

    return (
        <Page fluid={true} showsSearchBar showsFooter={false}>
            <FlexContainer className="innra-layout-container">
                <SideBar menu={menu} collapseInMobile />
                <FlexContainer style={{ flex: 1, justifyContent: "center", minWidth: 0 }}>
                    <div style={{ flex: 1, minWidth: 0 }}>{children}</div>
                </FlexContainer>
            </FlexContainer>
        </Page>
    );
}
export default authenticated_route(InnraLayout, {
    pathAfterAccessFailure: "/mitt/notandi",
    pathAfterLoginFailure: "/innskraning",
});
