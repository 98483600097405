import Router from "next/router";
import React from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import Page from "@components/layout/page";
import MenuBuilder from "@config/sidebars/innra_routes/MenuBuilder";

const authenticatedRoute = (Component = null, options = {}) => {
    class AuthenticatedRoute extends React.Component {
        state = {
            loading: true,
        };

        hasAccessToRoute(str, strArray) {
            for (var j = 0; j < strArray.length; j++) {
                if (str.startsWith(strArray[j])) return true;
            }
            return false;
        }

        hasAccess() {
            let menuItems = MenuBuilder(this.props.user);

            // Get parent links
            var links = menuItems.map((item) => item?.link);
            links = links.filter((link) => link != undefined);

            // Get sub links
            var sub_links = menuItems.map((item) =>
                item?.sub_pages?.map((sub_item) => sub_item?.link)
            );
            sub_links = sub_links.flat().filter((link) => link != undefined);
            links = links.concat(sub_links);
            if (!this.hasAccessToRoute(Router.pathname, links)) {
                return false;
            }
            return true;
        }

        denyAccess() {
            Router.replace(options.pathAfterAccessFailure || "/mitt/notandi");
            toast.error("Þú hefur ekki aðgang að þessari síðu", {
                position: "top-right",
                style: {
                    marginTop: 64,
                },
            });
        }

        componentDidMount() {
            if (!this.props.isLoggedIn) {
                Router.replace(options.pathAfterLoginFailure || "/innskraning");
                return;
            }
            if (!this.hasAccess()) {
                this.denyAccess();
            }
            this.setState({ loading: false });
        }

        componentDidUpdate() {
            if (!this.hasAccess()) {
                this.denyAccess();
            }
        }

        render() {
            const { loading } = this.state;
            if (loading) {
                return <Page></Page>;
            }

            return <Component {...this.props} />;
        }
    }

    return connect((state) => ({
        isLoggedIn: !!state?.auth?.user,
        roles: state?.auth?.user?.user_type,
        user: state?.auth?.user,
    }))(AuthenticatedRoute);
};

export default authenticatedRoute;
