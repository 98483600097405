export default {
    welcome: "Velkomin á 1819",
    enable_offer: "Virkja tilboð",
    website: "Vefsíða",
    opening_hours: "Opnunartímar",
    offer_enabled_for: "Tilboð virkt í",
    sec: "sek.",
    close: "Loka",
    about: "1819",
    most_popular: "Vinsælustu tilboðunum",
    newest_first: "Nýjustu tilboðum fyrst",
    oldest_first: "Elstu tilboðum fyrst",
    sort: "Raða eftir",
    active_now: "Virk núna",
    load_more: "Hlaða inn fleiri tilboðum...",
    use_this_code: "Notaðu þennan kóða til að virkja tilboðið",
    all_offers: "Öll tilboð",
    top_offers: "Helstu tilboð",
    home: "Heim",
    show_promocode: "Sýna afsláttarkóða",

    ad: "AUGLÝSING",

    monday: "Mánudagur",
    tuesday: "Þriðjudagur",
    wednesday: "Miðvikudagur",
    thursday: "Fimmtudagur",
    friday: "Föstudagur",
    saturday: "Laugardagur",
    sunday: "Sunnudagur",

    monday_medium: "mán.",
    tuesday_medium: "þri.",
    wednesday_medium: "mið.",
    thursday_medium: "fim.",
    friday_medium: "fös.",
    saturday_medium: "lau.",
    sunday_medium: "sun.",

    monday_short: "M",
    tuesday_short: "Þ",
    wednesday_short: "M",
    thursday_short: "F",
    friday_short: "F",
    saturday_short: "L",
    sunday_short: "S",

    offer_not_available_today: "Tilboð ekki virkt í dag",
    offer_not_available: "Tilboð ekki virkt",
    offer_was_available: "Tilboð var virkt",
    offer_today: "í dag",
    offer_is_available: "Tilboð er virkt",
    offer_is_available_all_day: "Tilboð er virkt í allan dag",
    offer_will_be_available: "Tilboð verður virkt",

    companies: "Fyrirtæki",
    individuals: "Einstaklingar",

    load_more_companies: "Sækja fleiri fyrirtæki",
    load_more_individuals: "Sækja fleiri einstaklinga",

    special_offer: "Sérkjör",
    two_for_one: "2 fyrir 1",
    introduction: "Kynning",

    login: "Innskráning",

    terms_conditions: "Notendaskilmálar",
    privacy_policy: "Persónuverndarskilmálar",

    searchPlaceholder: "Hverju ertu að leita að?",
    searchButton: "Leita",

    loginContainerTitle: "Innskráning á innri vef 1819.is",
    loginUsernameTitle: "Netfang",
    loginPasswordTitle: "Lykilorð",
    loginButtonTitle: "Innskrá",

    //==========================================================
    map: "Kortið",
    services_1819: "Þjónusta 1819",
    about_1819: "Um 1819",
    open: "Opið",
    closed: "Lokað",

    front_page: "Forsíða",
    search_results: "Leitarniðurstöður",
    search_results_for: "Leitarniðurstöður fyrir",

    nothing_found_with: "Ekkert fannst með leitarorðinu",
    nothing_found_using: "notast við",

    no_results_for: "Engar niðurstöður fundust fyrir",
    error_when_search: "Óvænt villa kom upp við leit",
    not_finding_what_you_are_looking_for: "Finnur þú ekki það sem þú leitar að?",
    we_can_help: "Við getum aðstoðað þig í síma 1819",
    call_1819: "Hringja í 1819",
    have_a_suggestion: "Hefur þú ábendingu fyrir okkur?",
    think_this_is_error: "Telur þú að þetta sé villa?",
    here_you_can_send_us: "Hér getur þú sent okkur ábendingu",
    send_us_a_message: "Senda okkur ábendingu",
    you_know_where_to_look: "...þú veist hvar þú átt að leita.",

    //==========================================================
    // INFO DETAILS
    info_details_opening_hours: "Opnunartímar",
    info_details_phone_number_singular: "Símanúmer",
    info_details_phone_number_plural: "Símanúmer",
    info_details_website_singular: "Vefsíða",
    info_details_website_plural: "Vefsíður",
    info_details_email_singular: "Netfang",
    info_details_email_plural: "Netföng",
    info_details_kennitala: "Kennitala",
    info_details_fyrirtaekjaskra: "Fyrirtækjaskrá",
    info_details_keldan: "Keldan fjárhagsupplýsingar",
    info_details_social_media: "Samfélagsmiðlar",
    info_details_map: "Kort",
    info_details_tags: "Merkingar",
    info_details_brands: "Vörumerki",
    info_details_services: "Þjónustur",
    info_details_other_listings: "Aðrar skráningar",
    info_details_employees: "Starfsfólk",
    info_details_navigate: "Vegvísun",
    info_details_more_info: "Nánari upplýsingar",

    map_search_on_map: "Leita á korti",

    //==========================================================
    // OPENING HOURS
    opening_hours_open_until: "Opið til",
    opening_hours_open_until_midnight: "Opið til miðnættis",
    opening_hours_opens_at: "Opnar kl.",
    opening_hours_closed: "Lokað",
    opening_hours_always_open: "Alltaf opið",

    //==========================================================
    // FOOTER

    footer_opening_hours: "Opnunartímar",
    footer_thjonustuver: "Þjónustuver",
    footer_skrifstofa: "Skrifstofa",
    footer_weekdays: "Virkir dagar",
    footer_weekends: "Helgar",
};
