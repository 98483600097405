import "bootstrap/dist/css/bootstrap.css";
import "react-month-picker/scss/month-picker.scss";
import "@styles/root.scss";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import React, { useEffect } from "react";
import Head from "next/head";

import "@config/icon_library";
import "@fortawesome/fontawesome-svg-core/styles.css";

import Modal from "react-modal";
import ReactGA_4 from "react-ga4";
import { GOOGLE_GA_MEASUREMENT_ID, THEME_COLOR, WEBPAGE_TITLE } from "@utils/constants";
import { Toaster } from "react-hot-toast";
import TorgidCookieConsent from "@components/ui/torgid_cookie_consent";
import { wrapper } from "@redux/store";
import Router, { useRouter } from "next/router";
import ServicesLayout from "@components/layout/services_layout";
import CompanyLayout from "@components/layout/company_layout";
import InnraLayout from "@components/layout/innra_layout";
import InnraSkrarLayout from "@components/layout/innra_skrar_layout";
import NextProgress from "next-progress";
import Bugsnag from "@utils/bugsnag";
import ErrorPage from "@components/error_page";
import MotionTransition from "@components/MotionTransition";
import Pixel from "@components/pixel/";
import { useDispatch } from "react-redux";
import { getUser } from "@redux/actions/authActions";
import PageVisibility from "react-page-visibility";
import { AbilityContext } from "@utils/can";
import ability from "@utils/ability";
const ErrorBoundary = Bugsnag.getPlugin("react");

Modal.setAppElement("#__next");

ReactGA_4.initialize(GOOGLE_GA_MEASUREMENT_ID);

// This default export is required in a new `pages/_app.js` file.
function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
        const handleRouteChange = () => {
            dispatch(getUser());
        };
        Router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            Router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, []);

    const handleFocusChange = (isVisible) => {
        if (isVisible) {
            dispatch(getUser());
        }
    };

    useEffect(() => {
        import("react-facebook-pixel")
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init("1480200352024791"); // facebookPixelId
                ReactPixel.pageView();

                router.events.on("routeChangeComplete", () => {
                    ReactPixel.pageView();
                });
            });
    }, [router.events]);

    if (router.pathname.startsWith("/mitt/skrar")) {
        return (
            <>
                <ErrorBoundary FallbackComponent={ErrorPage}>
                    <AbilityContext.Provider value={ability}>
                        <Head>
                            <meta name="theme-color" content={THEME_COLOR} key="theme-color"></meta>
                        </Head>
                        <InnraLayout>
                            <Toaster />
                            <InnraSkrarLayout>
                                <MotionTransition>
                                    <Component {...pageProps} />
                                </MotionTransition>
                            </InnraSkrarLayout>
                        </InnraLayout>
                    </AbilityContext.Provider>
                </ErrorBoundary>
            </>
        );
    }

    if (router.pathname.startsWith("/mitt") && !router.pathname.startsWith("/mitt/simi")) {
        return (
            <>
                <ErrorBoundary FallbackComponent={ErrorPage}>
                    <AbilityContext.Provider value={ability}>
                        <Head>
                            <meta name="theme-color" content={THEME_COLOR} key="theme-color"></meta>
                        </Head>
                        <InnraLayout>
                            <Toaster />
                            <MotionTransition>
                                <Component {...pageProps} />
                            </MotionTransition>
                        </InnraLayout>{" "}
                    </AbilityContext.Provider>
                </ErrorBoundary>
            </>
        );
    }

    if (router.pathname.startsWith("/mitt/simi")) {
        return (
            <ErrorBoundary FallbackComponent={ErrorPage}>
                <AbilityContext.Provider value={ability}>
                    <Toaster
                        toastOptions={{
                            position: "top-left",
                            style: { marginLeft: 275, marginTop: 15 },
                        }}
                    />
                    <Component {...pageProps} />
                </AbilityContext.Provider>
            </ErrorBoundary>
        );
    }

    if (router.pathname.startsWith("/um/thjonusta")) {
        return (
            <>
                <ErrorBoundary FallbackComponent={ErrorPage}>
                    <AbilityContext.Provider value={ability}>
                        <Head>
                            <meta name="theme-color" content={THEME_COLOR} key="theme-color"></meta>
                        </Head>
                        <ServicesLayout>
                            <Pixel name="FACEBOOK_PIXEL_1" />
                            <Toaster />
                            <Component {...pageProps} />
                        </ServicesLayout>
                    </AbilityContext.Provider>
                </ErrorBoundary>
            </>
        );
    }

    if (router.pathname.startsWith("/um/fyrirtaekid")) {
        return (
            <>
                <ErrorBoundary FallbackComponent={ErrorPage}>
                    <AbilityContext.Provider value={ability}>
                        <Head>
                            <meta name="theme-color" content={THEME_COLOR} key="theme-color"></meta>
                        </Head>
                        <CompanyLayout>
                            <Pixel name="FACEBOOK_PIXEL_1" />

                            <Toaster />
                            <MotionTransition>
                                <Component {...pageProps} />
                            </MotionTransition>
                        </CompanyLayout>
                    </AbilityContext.Provider>
                </ErrorBoundary>
            </>
        );
    }

    const description =
        "Upplýsingar um fyrirtæki, einstaklinga, kortavef, símaskrá og margt fleira. Þjónustuver & leit upplýsinga í síma 1819.";
    return (
        <PageVisibility onChange={handleFocusChange}>
            <ErrorBoundary FallbackComponent={ErrorPage}>
                <AbilityContext.Provider value={ability}>
                    <Head>
                        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                        <link rel="mask-icon" href="1819logo-white.svg" color={THEME_COLOR} />
                        <link rel="manifest" href="/site.webmanifest" />
                        <meta name="msapplication-TileColor" content={THEME_COLOR} />
                        <meta name="theme-color" content={THEME_COLOR} key="theme-color"></meta>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1.0"
                        ></meta>
                        <meta name="description" content={description} key="description"></meta>

                        {/* Outside Connections */}
                        <meta property="fb:app_id" content="899774864081824" />
                        <meta
                            name="google-site-verification"
                            content="etjAiv-i_cLYdb12CDsjC9OJ2nHNL9L7M6_7x23Esw8"
                        />
                        {/* Open Graph */}
                        <meta property="og:title" content={WEBPAGE_TITLE} key="og:title" />
                        <meta property="og:type" content="website" />
                        <meta
                            property="og:image"
                            content="https://1819.is/img/1819-share.png"
                            key="og:image"
                        />
                        <meta
                            property="og:description"
                            content={description}
                            key="og:description"
                        ></meta>
                    </Head>
                    <Pixel name="FACEBOOK_PIXEL_1" />
                    <NextProgress color={"#ff6515"} />
                    <Toaster />
                    <Component {...pageProps} />
                    <TorgidCookieConsent />
                </AbilityContext.Provider>
            </ErrorBoundary>
        </PageVisibility>
    );
}

export default wrapper.withRedux(MyApp);
