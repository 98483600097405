import React from "react";
import { Triangle } from "../shape/triangle";
import CloseIcon from "../icons/CloseIcon";

export function CloseButton({ onClick }) {
    return (
        <>
            <button
                className={"close-button"}
                aria-label="Loka"
                style={{
                    border: "none",
                    position: "absolute",
                    left: -10,
                    top: 10,
                }}
                onClick={() => onClick()}
            >
                <CloseIcon
                    style={{
                        color: "white",
                        margin: 10,
                        height: 30,
                        width: 30,
                        zIndex: 10,
                        position: "absolute",
                    }}
                />
                <Triangle size="50px" />
            </button>
        </>
    );
}

export default CloseButton;
