import React, { useRef, useState } from "react";
import { useRouter } from "next/router";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import classNames from "classnames";
import { FlexContainer } from "@components/ui/flex_container";
import CrossIcon from "./icons/CrossIcon";
import GoogleIcon from "./icons/GoogleIcon";
import Link from "next/link";
import useUser from "@hooks/use_user";
import { mutate } from "swr";

function T1819SearchBar({
    value,
    small,
    style,
    onSearch,
    maxWidth,
    placeholder,
    showGoogle,
    className,
    searchBarButtonStyle,
}) {
    const user = useUser();
    const t = GetTranslatedStrings();
    const router = useRouter();
    const searchBar = useRef(null);
    const [searchQuery, setSearchQuery] = useState((value ? value : router.query.q) || "");
    const _handleKeyDown = (e) => {
        if (e.key === "Enter") {
            _handleSearch();
        }
    };

    const _handleSearch = () => {
        if (onSearch) {
            onSearch(searchQuery);
            return;
        }
        if (!searchQuery || searchQuery?.length == 0) {
            return;
        }
        mutate(`1819/search?query=${searchQuery}`);
        router.push(`/?q=${searchQuery}`);
    };

    const _handleClear = () => {
        setSearchQuery("");
        if (searchBar) {
            searchBar.current.focus();
        }
    };

    return (
        <>
            <FlexContainer
                className={classNames("search-bar-container", className)}
                style={{
                    maxWidth: maxWidth && !small ? maxWidth : null,
                }}
            >
                <input
                    ref={searchBar}
                    autoFocus={!small}
                    type="text"
                    placeholder={placeholder || t.searchPlaceholder}
                    value={searchQuery}
                    style={{
                        ...style,
                    }}
                    onKeyDown={_handleKeyDown}
                    onChange={(event) => {
                        setSearchQuery(event.target.value);
                    }}
                    className={classNames(
                        "torgid-background-secondary torgid-border search-bar-input",
                        { small: small }
                    )}
                />
                <FlexContainer
                    style={{
                        position: "absolute",
                        flex: 1,
                        flexDirection: "row",
                        right: "10px",
                        padding: 0,
                        margin: 0,
                    }}
                    justifyContent="center"
                    alignItems="center"
                >
                    {user && user?.user_type != "normal" && showGoogle && (
                        <Link
                            href={
                                searchQuery.length == 0
                                    ? "#"
                                    : `https://www.google.com/search?q=${searchQuery}`
                            }
                        >
                            <a
                                target={searchQuery.length == 0 ? "" : "_blank"}
                                style={{ marginRight: small ? 0 : 5 }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#fbbc05",
                                        borderRadius: 100,
                                        flexGrow: 1,
                                    }}
                                >
                                    <GoogleIcon
                                        height={small ? 25 : 35}
                                        width={small ? 40 : 60}
                                        style={{
                                            marginBottom: "1px",
                                            justifySelf: "center",
                                            cursor: "pointer",
                                        }}
                                    />
                                </div>
                            </a>
                        </Link>
                    )}

                    {small && searchQuery?.length != 0 && (
                        <>
                            <CrossIcon
                                height="15px"
                                style={{
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    justifySelf: "center",
                                    cursor: "pointer",
                                }}
                                onClick={_handleClear}
                            />
                        </>
                    )}

                    <button
                        type="submit"
                        className={classNames("search-bar-button", {
                            small: small,
                        })}
                        style={{ ...searchBarButtonStyle }}
                        onClick={_handleSearch}
                    >
                        {t.searchButton}
                    </button>
                </FlexContainer>
            </FlexContainer>
            {!small && (
                <button
                    className={classNames("search-bar-button-below", {
                        small: small,
                    })}
                    type="submit"
                    onClick={_handleSearch}
                >
                    {t.searchButton}
                </button>
            )}
        </>
    );
}

export default T1819SearchBar;
