import React, { useEffect, useState } from "react";
import { fetcher1819 } from "@utils/fetcher1819";
import { FlexContainer } from "@components/ui/flex_container";
import Link from "next/link";
import HorizontalAd from "@components/HorizontalAd/HorizontalAd";
import dynamic from "next/dynamic";
const T1819SearchBar = dynamic(() => import("@components/T1819SearchBar"));

export default function FrontPage() {
    const [ad, setAd] = useState();
    useEffect(() => {
        (async () => {
            var fetchedAd = await fetcher1819("auglysing/1");
            if (fetchedAd.length > 0) {
                setAd(fetchedAd[0]);
            }
        })();
    }, []);

    return (
        <FlexContainer column justifyContent="center" alignItems="center">
            <div style={{ paddingTop: "5vh" }}></div>
            <Link href="/">
                <a>
                    <img
                        src="/img/1819logo-blue.svg"
                        alt="1819 merki"
                        style={{
                            height: "15vh",
                            maxHeight: "160px",
                            filter: "drop-shadow(0px 2px 2px #00000022)",
                        }}
                    />
                </a>
            </Link>
            <div style={{ paddingTop: "8vh" }}></div>
            <T1819SearchBar showGoogle maxWidth={900} />
            <div style={{ paddingTop: "8vh" }}></div>
            <HorizontalAd adPosition={1} />
            <div style={{ paddingTop: "21vh" }}></div>
        </FlexContainer>
    );
}
