import { PHONE_SYSTEM_API } from "./constants";

export async function phoneSystemFetcher(action, params) {
    const api_endpoint = PHONE_SYSTEM_API;

    var options = {
        crossDomain: true,
        timeout: 4000,
    };
    params = params.join("/");
    try {
        const result = await fetch(api_endpoint + action + "/" + params, options);
        if (result.ok) {
            return await result.text();
        } else {
            return null;
        }
    } catch (error) {
        console.log(error);
    }
}
