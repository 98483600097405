import React from "react";
import classNames from "classnames";
import Link from "next/link";
import CloseButton from "./close_button";
import { useRouter } from "next/router";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import IcelandFlag from "@components/icons/flags/IcelandFlag";
import UKFlag from "@components/icons/flags/UKFlag";
import { FlexContainer } from "@components/ui/flex_container";
import { THEME_COLOR } from "@utils/constants";
import useUser from "@hooks/use_user";

function SideMenu({ show, onClose }) {
    const user = useUser();
    const t = GetTranslatedStrings();
    const router = useRouter();

    return (
        <aside
            style={{
                maxWidth: "500px",
                minWidth: "300px",
                right: show ? 0 : "-400px",
                zIndex: 3000,
                borderLeft: "1px solid #e6e6e6",
            }}
            className={classNames("side-menu", "torgid-background")}
        >
            <div
                style={{
                    padding: 30,
                }}
            >
                <CloseButton onClick={onClose} />
                <FlexContainer justifyContent="around" className="mb-3 flags">
                    <Link href={router.asPath} locale="is">
                        <a aria-label="Íslenska">
                            <IcelandFlag
                                style={{
                                    height: 50,
                                    width: 50,
                                    border:
                                        router.locale == "is"
                                            ? "solid 3px " + THEME_COLOR
                                            : "solid 1px #ccc",
                                    borderRadius: 14,
                                }}
                            />
                        </a>
                    </Link>
                    <Link href={router.asPath} locale="en">
                        <a aria-label="English">
                            <UKFlag
                                style={{
                                    height: 50,
                                    width: 50,
                                    border:
                                        router.locale == "en"
                                            ? "solid 3px " + THEME_COLOR
                                            : "solid 1px #ccc",
                                    borderRadius: 14,
                                }}
                            />
                        </a>
                    </Link>
                </FlexContainer>
                {user ? (
                    <>
                        <Link href="/mitt/notandi">
                            <a>
                                <FlexContainer
                                    alignItems="center"
                                    gap={10}
                                    style={{ margin: "20px 0px" }}
                                >
                                    <img src="/img/sidebar/user.png" height={30} width={30} />
                                    <p style={{ margin: 0, fontWeight: 600 }}>{user.full_name}</p>
                                </FlexContainer>
                            </a>
                        </Link>

                        <Link href="/logout">
                            <a>
                                <FlexContainer
                                    alignItems="center"
                                    gap={10}
                                    style={{ margin: "20px 0px" }}
                                >
                                    <img src="/img/sidebar/log-out.png" height={30} width={30} />
                                    <p style={{ margin: 0, fontWeight: 600 }}>Útskráning</p>
                                </FlexContainer>
                            </a>
                        </Link>
                    </>
                ) : (
                    <></>
                )}

                <ul className="menu-items">
                    <MenuItem href="/" text={t.home} />
                    {user ? <></> : <MenuItem href="/innskraning" text={t.login} />}
                    <MenuItem href="/kort" text={t.map} />
                    <MenuItem href="/um/thjonusta/vorur" text={t.services_1819} />
                    <MenuItem href="/um/fyrirtaekid/um" text={t.about_1819} />
                </ul>
            </div>
        </aside>
    );
}

function MenuItem({ href, text }) {
    return (
        <li>
            <Link href={href}>
                <a>{text}</a>
            </Link>
        </li>
    );
}

export default SideMenu;
